<template>
    <section class="">
        <front-table :front_page="page"
                     :front_records="records"
                     :front_search="search"
                     :front_header="header"
                     :model="model"
                     @reload="handleReload"
                     @edit="handleEdit"


        >
            <div class="pull-right">
                <el-button type="info" icon="el-icon-coffee-cup" @click="batch=true">同步用户信息</el-button>
            </div>
        </front-table>
        <!--编辑界面-->
        <edit-dialog :edit_mode="edit_mode" :model="model" :single="single" :fields="fields" dual
                     @success="handleSuccess" @cancel="handleCancel"></edit-dialog>

        <div class="clearfix"></div>
        <!--批量界面-->
        <el-dialog :visible.sync="batch" title="同步用户信息" @close="disconnectPusher" @open="connectPusher" v-mobile-dialog>
            <div class="text-info">点击同步，开始同步腾讯网上信息</div>
            <pusher-information-box :events="events" progress></pusher-information-box>
            <div slot="footer" class="">
                <el-button :loading="process.loading" size="medium" @click="syncTMUsers">同步用户</el-button>
                <el-button type="primary" @click="batch=false" size="medium">返回</el-button>
            </div>
        </el-dialog>

    </section>
</template>

<script>
    import EditDialog from "../../components/edit-mobile-dialog";
    import FrontTable from "../../components/front-mobile-table";
    import FrontMixins from "../../common/mixins/front-mixins";
    import PushMixin from "../../common/mixins/echo-mixin";
    import PusherInformationBox from "../../components/PusherInformationBox";
    import AdminTmUser from "../mixins/admin-tm-user-mixin";

    export default {
        mixins: [FrontMixins,PushMixin,AdminTmUser],
        components: {PusherInformationBox, FrontTable, EditDialog},
    }

</script>

<style lang="scss">

</style>
