export default {
    name: 'admin-tm-user',

    data() {
        return {
            batch: false,
            payload: {id:'1',max:2},
            rooms: {},
            schedules:[],
        }
    },
    created() {
        this.model = 'Admin.TMUser';
    },
    methods: {
        syncTMUsers() {
            const vm = this;
            this.$api(this.model+'.publish',this.addChannel(this.payload),1).then((res)=>{
                vm.$notice.success(res.data.data);
                vm.reload();
                //vm.batch = false;
            });
        },
    }
}
